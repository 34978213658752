import { InteractionType, AccountInfo } from "@azure/msal-browser";
import {
  AuthCodeMSALBrowserAuthenticationProvider,
  AuthCodeMSALBrowserAuthenticationProviderOptions,
} from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";
import {
  BatchRequestContent,
  BatchRequestStep,
  BatchResponseContent,
  Client,
} from "@microsoft/microsoft-graph-client";
import { msalInstance } from "./index";
import placeholderImage from "./images/profile_placeholder.png";

type GraphUser = {
  "@odata.type": string;
  displayName: string;
  id: string;
  jobTitle: string;
  officeLocation: string;
  department: string;
  mail: string;
  manager?: {
    displayName: string;
    id: string;
  };
};
export type TreeUser = {
  name: string;
  id: string;
  positionName?: string;
  office?: string;
  department: string;
  mail: string;
  manager?: TreeUser | null;
  imageUrl?: string;
  parentId: string | null;
  _highlighted?: boolean;
  _expanded: boolean;
};
export type GraphResult = {
  rootNode: TreeUser | null;
  users: TreeUser[];
  errors: string[];
  departments: string[];
};

export type GraphRequest = {
  value: GraphUser[];
};

const apiCalls = {
  users:
    "/groups/97b9e2c0-2450-426f-aa3a-79fb4485a64b/members/microsoft.graph.user?$select=displayName,jobTitle,officeLocation,mail,id,department&$expand=manager($select=displayName,id)",
  excluded:
    "/groups/56858f47-0e15-4332-ac90-7435debb58db/members?$select=displayName",
};

const getGraphClient = (
  options: AuthCodeMSALBrowserAuthenticationProviderOptions
) => {
  const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
    msalInstance,
    options
  );

  return Client.initWithMiddleware({
    authProvider,
  });
};

// Maps GraphUser to treeuser
const mapGraphUserValues = (res: GraphUser[]) => {
  return res.map(
    ({
      displayName,
      jobTitle,
      officeLocation,
      id,
      mail,
      department,
      manager,
    }) => ({
      name: displayName,
      office: officeLocation,
      positionName: jobTitle,
      id: id,
      mail: mail,
      parentId: manager === undefined ? "" : manager.id,
      department: department,
      _expanded: false,
    })
  );
};

// Converts image blobs to base64 to display image.
// We cannot use blobs to display images because they dont persist after refreshes
const toBase64 = (blob: Blob): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(blob);
  });
};
//Taken from https://github.com/microsoftgraph/msgraph-sdk-javascript/blob/dev/docs/content/Batching.md
const b64toBlob = async (
  b64Data: any,
  contentType: string,
  sliceSize?: number
): Promise<Blob> => {
  contentType = "image/png";
  sliceSize = sliceSize || 512;

  let byteCharacters: string = atob(b64Data);
  let byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize);

    let byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    let byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  let blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

const getGraphUsername = (req: GraphRequest) => {
  const usernames = [];
  for (let user of req.value) {
    usernames.push(user.displayName);
  }
  return usernames;
};

export const getEmployees = async (
  account: AccountInfo,
  progressCallback: (current: number, total: number) => void
) => {
  const providerOptions = {
    interactionType: InteractionType.Popup,
    scopes: ["GroupMember.Read.All", "Group.Read.All", "User.Read.All"],
    account: account,
  };

  const users: TreeUser[] = [];
  const excludedUsers: string[] = [];
  const errors: string[] = [];
  const departments: string[] = [];
  const client = getGraphClient(providerOptions);
  // Get first batch of users

  excludedUsers.push(
    ...getGraphUsername(await client.api(apiCalls.excluded).get())
  );

  // Graph API limits to 100 values per respond, so we have to call the
  // @odata.nextlink to get the next values
  let prevReq = await client.api(apiCalls.users).get();
  users.push(...mapGraphUserValues(prevReq.value as GraphUser[]));
  while ("@odata.nextLink" in prevReq) {
    prevReq = await client.api(prevReq["@odata.nextLink"]).get();
    users.push(...mapGraphUserValues(prevReq.value as GraphUser[]));
  }

  // Variables for request batching to speed up image fetching
  let batches: BatchRequestContent[] = [];
  let currentBatchContent: BatchRequestContent = new BatchRequestContent();

  let rootNode = null; // specify the user that is the CEO.

  for (let i = 0; i < users.length; i++) {
    const user = users[i];
    user.imageUrl = placeholderImage;
    //remove excluded users
    if (excludedUsers.includes(user.name)) {
      users.splice(i, 1);
      i--;
      continue;
    }
    //add department if not exist
    if (!departments.includes(user.department) && user.department !== null) {
      departments.push(user.department);
    }
    try {
      // set manager object
      for (let _user of users) {
        if (_user.id === user.parentId) {
          user.manager = _user;
        }
      }
    } catch {}
    if (user.parentId === "" || !user.manager) {
      if (
        user.positionName === "CEO" ||
        user.positionName === "Chief Executive Officer"
      ) {
        // Set as root node
        user.manager = null;
        user.parentId = null;
        rootNode = user;
      } else {
        users.splice(i, 1); // Remove users with no manager. A chart cannot have multiple roots.
        i--;
        errors.push(
          `User '${user.name}' was removed because no manager was assigned.`
        );
        continue; //next iteration so we dont get the photo of deleted users
      }
    }
  }
  // Add create batches to fetch images from Graph API. One batch can contain max 20 requests
  // but Graph API may still decide to throttle the requests so we set the max batch size to 15 to be safe.
  for (let user of users) {
    const reqStep: BatchRequestStep = {
      id: user.id,
      request: new Request(`/users/${user.id}/photos/48x48/$value`, {
        method: "GET",
      }),
    };
    currentBatchContent.addRequest(reqStep);
    if (currentBatchContent.requests.size === 15) {
      batches.push(currentBatchContent);
      currentBatchContent = new BatchRequestContent();
    }
  }
  // add the remaining
  if (currentBatchContent.requests.size > 0) {
    batches.push(currentBatchContent);
  }
  let i = 0;
  for (let batchContent of batches) {
    const content = await batchContent.getContent();
    const batchRes = new BatchResponseContent(
      await client.api("/$batch").post(content)
    );

    const resMap = batchRes.getResponses();
    for (let [id, res] of resMap) {
      if (res.ok) {
        for (let user of users) {
          if (id === user.id) {
            try {
              let blob = await b64toBlob(await res.text(), "img/jpg");
              user.imageUrl = await toBase64(blob);
            } catch (error) {
              console.log(error);
            }
          }
        }
        i++;
      } else {
        console.warn(res.statusText, id);
      }
    }
    progressCallback(i, users.length);
  }
  users.sort((a, b) => a.name.localeCompare(b.name));
  departments.sort((a, b) => a.localeCompare(b));

  const result: GraphResult = {
    rootNode,
    users,
    errors,
    departments,
  };

  return result;
};
