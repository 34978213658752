import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { getEmployees, GraphResult, TreeUser } from "../graph";
import { OrganzationChart } from "./OrganizationChart";
import EmployeeSearch from "./EmployeeSearch";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  AppBar,
  Box,
  IconButton,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import ProgressBar from "./ProgressBar";
import DepartmentSearch from "./DepartmentSearch";
import { ErrorMessage } from "./ErrorMessage";

const AppHeader = styled(AppBar)({
  height: "auto",
  paddingBottom: 10,
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
});

const RefreshDataIcon = styled(RefreshIcon)({
  fontSize: 30,
  position: "relative",
  color: "white",
  top: "0.2em",
});

const Wrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "end",
  marginRight: 10,
  paddingTop: 10,
  flexGrow: 1,
  gap: 10,
});

export default function OrganizationChartPage() {
  const { accounts } = useMsal();
  const [data, setData] = useState<GraphResult>({
    rootNode: null,
    users: [],
    errors: [],
    departments: [],
  });
  const [orgTreeData, setOrgTreeData] = useState([] as TreeUser[]);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [searchedUser, setSearchedUser] = useState("");
  const [errorMessage, setErrorMessage] = useState({
    message: "",
    errorMessage: "",
  });

  const updateProgress = (current: number, total: number) => {
    setLoadingProgress((current / total) * 100);
  };
  const onEmployeeSearchChange = (id: string | null) => {
    const user = id === null ? "" : id;
    setSearchedUser(user);
  };

  const fetchOrgData = async () => {
    setErrorMessage({
      message: "",
      errorMessage: "",
    });
    setLoadingProgress(0);
    if (data.users.length > 0) {
      setData({
        rootNode: null,
        users: [],
        errors: [],
        departments: [],
      });
    }
    try {
      let res = await getEmployees(accounts[0], updateProgress);
      setData(res);
      setOrgTreeData(res.users);
    } catch (error) {
      setErrorMessage({
        message: "Failed to fetch data! Please contact IT department",
        errorMessage: (error as Error).message,
      });
    }
  };

  const onDepartmentSearchChange = (department: string) => {
    onEmployeeSearchChange("");
    if (department !== "") {
      const departmentUsers: TreeUser[] = [];
      for (let user of data.users) {
        if (user.department === department) {
          let manager = user.manager;
          while (manager) {
            departmentUsers.push(manager);
            manager = manager.manager;
          }
          departmentUsers.push(user);
        }
      }
      const names = departmentUsers.map((emp) => emp.name);
      setOrgTreeData(
        departmentUsers.filter(
          ({ name }, index) => !names.includes(name, index + 1)
        )
      );
    } else {
      for (let user of data.users) {
        user._expanded = false;
      }
      setOrgTreeData(data.users);
    }
  };

  useEffect(() => {
    fetchOrgData();
  }, []);

  return (
    <>
      <AppHeader>
        <Typography style={{ marginLeft: 10, marginTop: 10 }} variant="h5">
          NTi Organizational Chart
        </Typography>
        <Wrapper>
          <EmployeeSearch
            users={orgTreeData}
            onChange={onEmployeeSearchChange}
          />
          <DepartmentSearch
            departments={data.departments}
            onChange={onDepartmentSearchChange}
          />
          <Tooltip title="Refresh chart data">
            <IconButton onClick={fetchOrgData}>
              <RefreshDataIcon style={{ top: 0 }} />
            </IconButton>
          </Tooltip>
        </Wrapper>
      </AppHeader>
      {errorMessage.message === "" ? (
        <>
          {data.users.length === 0 ? (
            <ProgressBar value={loadingProgress} />
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <OrganzationChart
                searchedUser={searchedUser}
                data={orgTreeData}
                rootNode={data.rootNode}
                onNodeClick={() => {
                  //do nothing
                }}
              />
            </Box>
          )}
        </>
      ) : (
        <ErrorMessage
          message={errorMessage.message}
          errorMessage={errorMessage.errorMessage}
        />
      )}
    </>
  );
}
